<template>
  <div class="layout">
    <div class="introduction-background">
      <img src="../../assets/light.svg" />
    </div>

    <div class="mute-button">
      <Mute/>
    </div>

    <div class="introduction">
      <div class="introduction-header">
        <div class="introduction-logo" >
          <Logo />
        </div>
      </div>
      <div class="introduction-content">

        <div class="introduction-illustration">
          <video
            v-if="!$device.isSafari && !$device.isIphone && !$device.isTablet"
            muted="muted"
            autoplay="autoplay"
            loop="loop"
            src="/videos/introduction.webm"
          />
          <img v-else src="/images/introduction.png">
        </div>
        
        <div class="introduction-text">

          <template v-for="(text, index) in data.multipleTexts" :key="text.text">
            <transition name="t-fader-absolute-top--delayed">
              <message-toast
                :isIntro="true"
                :isLast="index === data.multipleTexts.length - 1"
                v-if="index === currentMessage"
                :text="text.text"
                look="white"
                class="introduction-message"
                @next="nextMessage"
                :images="index === 0 ? data.image : []"
              />
            </transition>
          </template>
        </div>
      </div>
    </div>
    <transition name="t-fader">
      <video-player
        v-if="isTransitionEnd"
        :forcePlay="true"
        :loop="false"
        @ended="nextLevel"
        :src="data.video[0].url"
        :isStoppable="false"
        class="u-wrapper-panel u-fit-cover"
      />
    </transition>
  </div>
</template>

<script>
import { Audio, audio } from '@/utils/Audio'

import Logo from "../elements/Logo.vue"
import Mute from "../elements/Mute"
import MessageToast from "../elements/MessageToast.vue"
import VideoPlayer from "../common/players/VideoPlayer"


export default {
  name: "Introduction",

  components: {
    Logo,
    Mute,
    MessageToast,
    VideoPlayer
  },

  data() {
    return {
      currentMessage: -1,
      isTransitionEnd: false
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/homepage']
    },

    firstSection () {
      const sections = this.$store.getters['data/sections']
      if (sections.length) {
        return this.$store.getters['data/sections'][0]
      }
      return null
    }
  },

  created () {
    this.currentMessage++
  },

  methods: {
    nextMessage () {
      if(this.currentMessage === 0 && !this.musicIsMuted){
        this.$store.state.global.musicIsPlaying = true
      }

      audio.src = this.$store.state.global.sounds.nextLevel
      audio.play()

      if (this.currentMessage === this.data.multipleTexts.length - 1) {
        // this.nextLevel()
        this.isTransitionEnd = true
        this.currentMessage++
      }else{
        this.currentMessage++
      }
    },
    nextLevel () {
      this.$router.push({name: 'chapters', params: {section: this.firstSection.slug}})
    }
  },
};
</script>

<style lang="stylus" scoped>

.layout
  position fixed
  width 100%
  height 100%
  // background #0e0284
  background url('/images/blue.jpg') repeat

.introduction-background
  position absolute
  // z-index -1
  width 100%
  height 100%
  pointer-events none

  img
    object-fit cover
    width 100%
    height 100%

.introduction
  display flex
  flex-direction column
  align-items center
  justify-content space-between
  height 100%

.introduction-content
  display flex
  flex 1
  width: 100%;
  justify-content: center;
@media screen and (max-width: 1200px)
  .introduction-content
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

.introduction-logo
  margin-top: 76px;
@media screen and (max-width: 1200px)
  .introduction-logo
    margin-top: 46px;
@media screen and (max-width: 800px)
  .introduction-logo
    margin-top: 43px;

.introduction-text
  position relative
  max-width 780px
  margin 82px 40px
@media screen and (max-width: 1200px)
  .introduction-text
    max-width: 600px;
    margin: 32px 40px 12px;
    transform: translate(0, -50px);
    bottom: 0;
    z-index 10
@media screen and (max-width: 800px)
  .introduction-text
    position: absolute;
    transform: translate(0, 0);
    max-width: 450px;
    margin: 22px 20px 36px;
    display: flex;
    flex-direction: column-reverse;
    
.introduction-message
  width 100%

.introduction-illustration
  display flex
  justify-content: flex-end;
  position: relative;
  z-index: -1;
  flex: 0 0 30%;
  video, img
    align-self: flex-end;
    position: fixed;
    bottom: 0;
    width: 40%;
    height: auto;
    max-width: 640px;
@media screen and (max-width: 1620px)
  .introduction-illustration
    video, img
      left 0
@media screen and (max-width: 1200px)
  .introduction-illustration
    width 290px
    video, img
      position: relative;
      bottom: inherit;
      width 100%
      height auto
@media screen and (max-width: 800px)
  .introduction-illustration
    width 230px


.mute-button
  position fixed
  z-index 100
  bottom 28px
  left 28px
  transition transform 0.15s

  &:hover
    transform scale(1.05)

@media screen and (max-width: 1300px)
  .mute-button
    bottom 28px
    left 28px
@media screen and (max-width: 800px)
  .mute-button
    bottom initial
    left initial
    right 20px
    top 20px
    
</style>
