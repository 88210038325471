<template>
  <div style="position: fixed; width: 0; height: 0; overflow: hidden; top: -99px">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="icon-diamond" viewBox="0 0 9 11">
          <title>Icon Diamond</title>
          <path d="M0.864193 5.13432L3.6476 10.2801C4.11175 11.1382 5.34304 11.1382 5.80719 10.2801L8.5906 5.13432C8.84803 4.65839 8.7635 4.07003 8.3825 3.68582L5.59909 0.87902C5.11885 0.394745 4.33594 0.394745 3.8557 0.87902L1.07229 3.68582C0.691284 4.07003 0.606757 4.65839 0.864193 5.13432Z"/>
        </symbol>
        <symbol id="question-tail-left" viewBox="0 0 40 34">
          <title>Question Tail Left</title>
          <path d="M0 29.8431V0H39.5L6.82843 32.6716C4.30857 35.1914 0 33.4068 0 29.8431Z"/>
        </symbol>
        <symbol id="question-mark" viewBox="0 0 44 44">
          <title>Question Mark</title>
          <circle cx="22" cy="22" r="22" fill="white"/>
          <path d="M21.6462 26.4329C22.5227 26.4329 23.1573 25.7378 23.2782 24.952L23.3689 24.408C26.6631 23.7431 29.3227 22.1716 29.3227 18.3333V18.2729C29.3227 14.2533 26.3609 12.0169 22.0089 12.0169C19.3493 12.0169 17.3244 12.8631 15.7227 14.1929C15.1787 14.6462 14.9369 15.2507 14.9369 15.8249C14.9369 16.9733 15.904 17.9405 17.0524 17.9405C17.5662 17.9405 18.0498 17.7289 18.4124 17.4569C19.5004 16.6409 20.6187 16.248 21.9182 16.248C23.6711 16.248 24.6987 17.0338 24.6987 18.3938V18.4542C24.6987 19.9351 23.4898 20.7511 20.8604 21.0836C19.8933 21.2045 19.3191 21.8996 19.4702 22.8969L19.7724 24.952C19.8933 25.768 20.528 26.4329 21.4044 26.4329H21.6462ZM18.7751 30.9965V31.0569C18.7751 32.5076 19.8933 33.6258 21.4044 33.6258C22.9156 33.6258 24.0338 32.5076 24.0338 31.0569V30.9965C24.0338 29.5458 22.9156 28.4276 21.4044 28.4276C19.8933 28.4276 18.7751 29.5458 18.7751 30.9965Z" fill="#009978"/>
        </symbol>
        <symbol id="arrow-left" viewBox="0 0 10 11">
          <title>Arrow Left</title>
          <path d="M0.513646 6.25682L8.77543 10.3877C9.33804 10.669 10 10.2599 10 9.6309L10 1.36911C10 0.740092 9.33804 0.33098 8.77544 0.612285L0.513646 4.74318C-0.11001 5.05501 -0.11001 5.945 0.513646 6.25682Z"/>
        </symbol>
        <symbol id="arrow-right" viewBox="0 0 10 11">
          <title>Arrow right</title>
          <path d="M9.48635 6.25682L1.22457 10.3877C0.661957 10.669 -3.23503e-08 10.2599 -5.98455e-08 9.6309L-4.2098e-07 1.36911C-4.48475e-07 0.740092 0.661956 0.33098 1.22456 0.612285L9.48635 4.74318C10.11 5.05501 10.11 5.945 9.48635 6.25682Z"/>
        </symbol>
      </defs>
    </svg>
    <!-- <svg preserveAspectRatio="xMidYMid meet">
      <use xlink:href="#arrow-left"/>
    </svg> -->
  </div>
</template>


