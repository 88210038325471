<template>
  <div>
    <div class="burger-button" @click="openBurger">
      <img src="../../assets/burger.svg" />
    </div>

    <div class="mute-button">
      <Mute/>
    </div>

    <div
      class="burger-pannel"
      :class="{ active: isOpen || isAnimation, 'u-pointer-none': isAnimation }"
      @click="closeBurger"
    >
      <div class="burger-content" :class="{ active: isOpen || isAnimation }" @click.stop>
        <div class="burger-close" @click="closeBurger" :class="{'visible': !isAnimation}">
          <img src="../../assets/close.svg" />
        </div>

        <div class="burger-items">
          <router-link
            v-for="item in itemsSpliced"
            :key="item.text"
            class="burger-item"
            :class="{ active: item.index <= currentIndex }"
            :to="{name: 'chapters', params: {section: item.slug}}"
            @click="() => navigate(item.slug)"
          >
            <div class="burger-text">
              {{ item.title }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { audio } from '@/utils/Audio'

import Mute from "../elements/Mute"

export default {
  name: "BurgerPannel",

  components: {
    Mute
  },

  props: {
    items: {
      type: Object,
      default: () => {return {}}
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    isAnimation: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    itemsSpliced(){
      // coz we don't want the last item ("à bientôt")
      return this.items.slice(0, this.items.length - 1)
    }
  },

  methods: {
    navigate(slug) {
      this.$ga.send('navigation', 'menu', 'navigation', slug)
      this.closeBurger(slug)
    },
    closeBurger() {
      this.isOpen = false;

      setTimeout(() => {
        audio.pause()
      }, 400)
    },
    openBurger(){
      this.isOpen = true;

      setTimeout(() => {
        audio.src = this.$store.state.global.sounds.metro
	      audio.play()
      }, 400)

      this.$ga.send('navigation', 'menu', 'open')
    }
  }
};
</script>

<style lang="stylus" scoped>

.burger-button
  position fixed
  z-index 100
  bottom 10px
  left 10px
  cursor pointer
  transition 0.15s

  &:hover
    transform scale(1.05)

  img
    width 100%

.mute-button
  position fixed
  z-index 100
  bottom 180px
  left 35px
  transition transform 0.15s

  &:hover
    transform scale(1.05)

@media screen and (max-width: 1300px)
  .mute-button
    bottom 130px
    left 28px
@media screen and (max-width: 800px)
  .mute-button
    bottom initial
    left initial
    right 90px
    top 20px
    

@media screen and (max-width: 1300px)
  .burger-button
    width 120px
@media screen and (max-width: 800px)
  .burger-button
    top 0
    right 0
    left auto
    bottom auto
    width 84px
    margin 10px


.burger-pannel
  position fixed
  z-index 200
  left 0
  right 0
  top 0
  bottom 0
  background rgba(0, 12, 135, 0.5)
  transform translate(0%, 100%)
  transition 0.4s
  transition-delay 0.5s

  &.active
    transform translate(0%, 0%)
    transition-delay 0s
  
.burger-content
  background white
  height 100%
  width 475px
  padding 30px 33px
  overflow-y auto
  display flex
  flex-direction column
  transform translate(-100%, 0px)
  transition 0.4s
  transition-delay 0s

  &.active
    transform translate(0%, 0%)
    transition-delay 0.25s


@media screen and (max-width: 800px)
  .burger-content
    width 100%

.burger-close
  cursor pointer
  align-self flex-start
  transition 0.15s
  opacity 0
  
  &.visible
    opacity 1

  &:hover
    transform scale(1.05)

  img
    width 100%

@media screen and (max-width: 800px)
  .burger-close
    width 47px
    height 47px

.burger-items
  margin 40px 33px 0
  display flex
  flex-direction column
@media screen and (max-width: 800px)
  .burger-items
    margin: 50px 22px 0
@media screen and (max-height: 700px)
  .burger-items
    margin: 50px 22px 0

.burger-item
  font-weight 600
  font-size 30px
  line-height 38px
  color #000C87
  display block
  position relative
  border-left 4.5px solid currentColor
  min-height 130px

  &:last-of-type
    border-left-color transparent
    min-height 30px
    height 30px

  &:before
    left -13.5px
    content ''
    position absolute
    width 22px
    height 22px
    background white
    border 2px solid #000C87
    border-radius 50%

  &.active
    &:before
      background #000C87

@media screen and (max-width: 1300px)
  .burger-item
    min-height 100px
    font-size 26px
    line-height 34px
@media screen and (max-width: 800px)
  .burger-item
    max-width 360px
    min-height 80px
    font-size 20px
    line-height 25px
@media screen and (max-height: 700px)
  .burger-item
    min-height 80px


.burger-text
  height: 60px;
  transform rotate(-15deg) translate(36px, -47px)
@media screen and (max-width: 800px)
  .burger-text
    transform: rotate(-15deg) translate(30px, -36px);
</style>
