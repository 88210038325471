export default {
	namespaced: true,

	state: {
		options: {},
		sounds: {
			background: '/sounds/background.mp3',
			badAnswer: '/sounds/bad-answer.mp3',
			goodAnswer: '/sounds/good-answer.mp3',
			malik: '/sounds/malik.mp3',
			metro: '/sounds/metro.mp3',
			nextLevel: '/sounds/next-level.mp3',
			nextScreen: '/sounds/next-screen.mp3',
			quizz: '/sounds/quizz.mp3'
		},
		musicIsMuted: false,
		musicIsPlaying: false
	},

	getters: {
		isLoading(state) {
			return state.isLoading;
		},
		musicIsMuted(state) {
			return state.musicIsMuted;
		},
		musicIsPlaying(state) {
			return state.musicIsPlaying;
		}
	},

	actions: {
		showPrehome( { commit, state } ) {
			commit('showPrehome');
		}
	},

	mutations: {
		loadingStart(state) {
			state.isLoading = true;
		}
	}
}
