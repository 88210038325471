<template>
  <router-link to="/" class="logo">
    <img
      src="../../assets/logo.png"
      alt="Bonjour RATP"
      srcset="../../assets/logo@2x.png 2x"
    />
  </router-link>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style lang="stylus" scoped>

.logo
  img
    width 100%

@media screen and (max-width: 1300px)
  .logo
    width 130px
    display inline-block
@media screen and (max-width: 800px)
  .logo
    width 108px
    display inline-block
</style>
