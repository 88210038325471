<template>
  <div v-if="!choosen">
    <div class="cookie u-z-front u-pad-md u-row u-middle">
      <div class="u-w12of12--sm">
        <div class="u-right u-white t-bold t-text u-marg-b-xs u-center u-cursor-pointer" @click="onDecline">
          Continuer sans accepter
        </div>
        <div class="cookie__wrapper u-bg-main-color u-pad-x-md u-pad-y-sm u-pad-y-md--sm u-shodow u-radius">
          <div class="t-text t-bold u-center">
            Nous utilisons des cookies
          </div>
          <div class="u-marg-t-xs u-marg-t-sm--sm">
            <checkbox-field @onChange="value => {isAnalytics = value}" :default="isAnalytics" name="Google Analytics" slug="analytics"/>
            <checkbox-field :default="true" name="Fonctionnels" slug="fonctionnals" class="u-pointer-none u-marg-t-xxs--sm"/>
          </div>
          <div class="u-marg-t-sm u-center">
            <app-button @click="onAccept" :isSmall="true">
              Valider
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxField from './CheckboxField'
import AppButton from '@/components/common/AppButton'

import { storage } from '@/utils/storage'

export default {
  components: {
    CheckboxField,
    AppButton
  },

  data () {
    return {
      isMore: false,
      choosen: false,
      isAnalytics: true,
      analytics: false
    }
  },

  created () {
    storage.getItem(`analytics`).then(res => {
      if (res !== null) {
        this.choosen = true
        this.addAnalytics()
      }
    })
  },

  methods: {
    onDecline() {
      this.choosen = true
    },
    onAccept() {
      if (this.isAnalytics) {
        this.addAnalytics()
      }

      this.choosen = true
    },
    addAnalytics() {
      storage.setItem(`analytics`, true)

      let script = document.createElement('script')
      script.setAttribute('async', 'true')
      script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GOOGLE_ANALYTICS)
      document.head.appendChild(script)
      window.dataLayer = window.dataLayer || []
      window.gtag = function (){window.dataLayer.push(arguments)}
      gtag('js', new Date())
      gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS)

      this.$hub.emit('analytics:enable')
    }
  },

  watch: {
    $route (to, from) {
      if (from.name && !this.choosen) {
        this.onAccept()
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.cookie
  position absolute
  top 0
  right 0
  
  +mq($until: 'tablet')
    width 100%
    height 100%
    background-color rgba($colors.black, .5)
  
  &__wrapper
    width 300px

    +mq($until: 'tablet')
      width 100%
</style>