<template>
  <div class="mute" :class="[{isMuted: (this.$store.state.global.musicIsMuted && this.$store.state.global.musicIsPlaying) || !this.$store.state.global.musicIsPlaying}]" @click="mute">
    <img src="../../assets/sound.svg" />
  </div>
</template>

<script>
export default {
  methods: {
    mute () {
      if(!this.$store.state.global.musicIsPlaying){
        this.$store.state.global.musicIsPlaying = true
        this.$store.state.global.musicIsMuted = false
      }else{
        this.$store.state.global.musicIsMuted = !this.$store.state.global.musicIsMuted
      }
    }
  }
};
</script>

<style lang="stylus" scoped>

.mute
  cursor pointer
  background transparent
  transition: opacity .5s linear;
  opacity 1
  background white
  border-radius 50%
  padding 12px

  img
    display block
    height 31px

  &.isMuted
    opacity 0.5


@media screen and (max-width: 1300px)
  .mute
    padding 23px

@media screen and (max-width: 800px)
  .mute
    padding 18px 16px

    img
      height 24px

</style>
