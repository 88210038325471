import { api } from '@/store/interfaces/apiCraft'
// import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    homepage: {},
    endpage: {},
    sections: [],
    chapters: [
      {
        chapter: {
          number: "01",
          text: "Retrouvez vos essentiels comme vos recherches d’itinéraires",
        },
        steps: [
          {
            message: {
              name: "Malik",
              text: "Mince j’ai pas rechargé mon pass navigo ..",
              next: true,
            },
          },
          {
            quizz: [
              {
                question:
                  "D’ailleurs, tu sais à partir de quand les tickets en cartons seront supprimés?",
                propositions: ["Janvier 2022", "Mai 2022", "C’EST DÉJÀ FINI"],
                answerIndex: 0,
                info: "Depuis 2005 et la réforme des transports tes titres de transports sont totalement dézonnés pour un maximum de liberté",
                more: "https://www.google.com",
              },
              {
                question: "Quelle station est la plus neuve ?",
                propositions: ["Nation", "Voltaire", "Avron"],
                answerIndex: 1,
                info: "La station a été rénovée en 2018",
                more: "https://www.google.com",
              },
            ],
          },
          {
            message: {
              name: "Malik",
              text: "Génial !",
              next: true,
            },
          },
        ],
      },
      {
        chapter: {
          number: "02",
          text: "To be continued",
        },
        steps: [],
      },
    ],
    loaded: false,
  },

  getters: {
    chapters: (state) => {
      return state.chapters
    },
    homepage: (state) => {
      return state.homepage
    },
    endpage: (state) => {
      return state.endpage
    },
    sections: (state) => {
      return state.sections
    },
    sectionBySlug: state => slug => {
      return state.sections.find(section => section.slug === slug)
    },
    loaded: (state) => {
      return state.loaded
    },
  },

  actions: {
    async global ({ commit, state }) {
      const promises = []

      promises.push(new Promise(resolve => {
        const { request, cache } = api.getHomepage()
        // cache.then((res) => {
        //   if (res !== null) {
        //     commit('global', res)
        //     commit('loaded', true)
        //   }
        // })

        request.then((res) => {
          commit('homepage', res)
          resolve()
        })
      }))

      promises.push(new Promise(resolve => {
        const { request, cache } = api.getEndpage()
        // cache.then((res) => {
        //   if (res !== null) {
        //     commit('global', res)
        //   }
        // })

        request.then((res) => {
          commit('endpage', res)
          resolve()
        })
      }))

      promises.push(new Promise(resolve => {
        const { request, cache } = api.getSection()
        // cache.then((res) => {
        //   if (res !== null) {
        //     commit('global', res)
        //   }
        // })

        request.then((res) => {
          commit('sections', res)
          resolve()
        })
      }))

      return Promise.all(promises).then(() => {
        commit('loaded', true)
      })
    },
  },

  mutations: {
    loaded(state, payload) {
      state.loaded = payload
    },
    homepage(state, payload) {
      state.homepage = payload
    },
    sections(state, payload) {
      let indice = 0

      payload.forEach((section, i) => {
        section.index = i
        section.questions.forEach(question => {
          indice++
          question.indice = indice
        })
      })
      state.sections = payload
    },
    endpage(state, payload) {
      state.endpage = payload
    }
  },
}
