<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <cookie/>
    <music-player />
    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component v-if="loaded" :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import App from './App'


import SymbolsSvg from '@/components/SymbolsSvg'
import Cookie from '@/components/common/cookie'
import MusicPlayer from '@/components/elements/MusicPlayer'

export default {
  name: 'App',

  mixins: [App],

  computed: {
    loaded () {
      return this.$store.getters['data/loaded']
    }
  },

  created () {
    this.$store.dispatch('data/global')
  },

  components: {
    SymbolsSvg,
    Cookie,
    MusicPlayer
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto

</style>