<template>
  <div class="layout">
    <video-player
      v-if="!switchVideo"
      :forcePlay="true"
      :loop="!listenEndVideo"
      @ended="onEndedLoop"
      :src="data.video[0].url"
      :isStoppable="false"
      class="u-wrapper-panel u-fit-cover"
    />
    <video-player
      v-else
      :forcePlay="true"
      @ended="onEndedVideo"
      :src="data.video[1].url"
      :isStoppable="false"
      class="u-wrapper-panel u-fit-cover"
    />

    <div class="site-logo">
      <Logo />
    </div>

    <template v-if="!isMenuAnimation">
      <div class="chapter-pannel u-invisible-scroll">
        <chapter-toast
          :class="[{isMobileHidden: currentMessage > 0}]"
          :number="isFinalSection ? 0 : data.index + 1"
          :text="data.title"
        />
        <div class="quizz-pannel">
          <template v-for="(question, index) in data.questions">
            <transition name="t-fader-absolute-top--delayed">
              <quizz v-if="index === currentQuizz" :index="index" :data="question" @next="nextQuizz" :chapter="data.index" />
            </transition>
          </template>
        </div>
      </div>


      <template v-for="(text, index) in data.multipleTexts" :key="text.text">
        <transition name="t-fader-absolute-bottom--delayed">
          <div v-if="index === currentMessage" class="message-pannel">
            <message-toast
              :isChapter="true"
              name="Malik"
              :text="text.text"
              @next="nextMessage"
            />
          </div>
        </transition>
      </template>
      <template v-for="(img, index) in data.image" :key="data.image.id">
        <transition name="t-fader--delayed">
          <app-phone v-if="index === currentIllu" :url="img.url" @next="nextIllu" />
        </transition>
      </template>
    </template>

    <burger-pannel :isAnimation="isMenuAnimation" :items="sections" :currentIndex="data.index" />
  </div>
</template>

<script>
import Logo from "../elements/Logo.vue"
import ChapterToast from "../elements/ChapterToast.vue"
import AppPhone from "../elements/AppPhone.vue"
import Quizz from "../elements/Quizz.vue"
import BurgerPannel from "../elements/BurgerPannel.vue"
import MessageToast from "../elements/MessageToast.vue"
import VideoPlayer from "../common/players/VideoPlayer"

export default {
  name: "Chapters",
  components: {
    Logo,
    ChapterToast,
    Quizz,
    BurgerPannel,
    MessageToast,
    VideoPlayer,
    AppPhone
  },
  data() {
    return {
      currentMessage: -1,
      currentQuizz: -1,
      currentIllu: -1,
      switchVideo: false,
      listenEndVideo: false,
      // burgerItems: [
      //   { text: "Itinéraire", active: true },
      //   { text: "Titre de transport", active: true },
      //   { text: "Favoris", active: true },
      //   { text: "Réservez vos moyens de transports", active: true },
      //   { text: "Vérifiez et renseignez l’affluence", active: false },
      //   { text: "Accédez aux plans en hors-connexion", active: false },
      // ],
      isMenuAnimation: false
    };
  },

  created () {
    // this.currentMessage++
    // this.currentQuizz++
  },

  mounted () {
    setTimeout(() => {
      this.currentMessage = 0
    }, 1000)
  },

  computed: {
    chapters() {
      return this.$store.getters["data/chapters"]
    },
    quizz() {
      return this.chapters[0].steps[0]?.quizz
    },
    message() {
      return this.chapters[0].steps[0]?.message
    },
    sections () {
      return this.$store.getters['data/sections']
    },
    data () {
      return this.$store.getters['data/sectionBySlug'](this.$route.params.section)
    },
    isFinalSection () {
      return this.sections.length === this.data.index + 1
    }
  },
  methods: {
    nextMessage () {
      this.currentMessage++
    },
    nextQuizz () {
      this.currentQuizz++
    },
    nextIllu () {
      this.currentIllu++
      if (this.currentIllu === this.data.image.length && !(this.sections.length === this.data.index + 2)) {
        this.$ga.send('navigation', 'chapter', 'next', this.chapters[this.data.index + 1].slug)
      }
    },
    onEndedLoop () {
      // shitty dev for last ratp stuff
      const next = this.sections[this.data.index + 1]
      if (!next) {
        this.$router.push({name: 'end'})
        return
      }
      if (this.listenEndVideo) {
        this.switchVideo = true
      }
    },
    onEndedVideo () {
      const next = this.sections[this.data.index + 1]
      if (next) {
        this.isMenuAnimation = next.index + 1 === this.sections.length ? false : true
        this.$router.push({name: 'chapters', params: {section: next.slug}})
      }
      else {
        this.$router.push({name: 'introduction'})
      }
    }
  },

  watch: {
    $route () {
      this.listenEndVideo = false
      this.switchVideo = false
      this.currentQuizz = -1
      this.currentIllu = -1

      setTimeout(() => {
        this.isMenuAnimation = false
        setTimeout(() => {
          this.currentMessage = 0
        }, 1000)
      }, 2500)
    },

    currentMessage () {
      if (this.currentMessage >= this.data.multipleTexts.length) {
        this.currentQuizz++
      }
    },

    currentQuizz () {
      if (this.currentQuizz >= this.data.questions.length) {
        this.currentIllu++
      }
    },

    currentIllu () {
      if (this.currentIllu >= this.data.image.length) {
        this.listenEndVideo = true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
// @import '~@/styles/settings/variables'

.layout
  position fixed
  background-size cover
  width 100%
  height 100%

.site-logo
  position fixed
  z-index 100
  top 0
  left 0
  margin 55px
@media screen and (max-width: 1300px)
  .site-logo
    margin 45px 35px
@media screen and (max-width: 800px)
  .site-logo
    margin 33px 20px

.chapter-pannel
  position fixed
  z-index 20
  top 0
  right 0
  bottom 0
  padding 36px 26px
  display flex
  flex-direction column
  align-items center
  max-width 528px
  width 100%
  overflow-y auto
  scroll-behavior smooth

@media screen and (max-width: 1300px)
  .chapter-pannel
    max-width 400px

@media screen and (max-width: 800px)
  .chapter-pannel
    max-width 100%
    width 100%
    justify-content flex-end
    top: auto;
    padding-top: 0;

    .isMobileHidden
      display: none;

.quizz-pannel
  display flex;
  flex-direction column
  width 100%
@media screen and (max-width: 800px)
  .quizz-pannel
    flex-direction column-reverse

.message-pannel
  position fixed
  z-index: 20
  bottom 0
  left 0
  right 0
  margin 0 26px 36px
  display: flex
  justify-content: center
  flex-direction: column-reverse

</style>
