import { emitter } from '@/utils/emitter'

export class Audio {
  constructor () {
    this.play = this.play.bind(this)
    this.pause = this.pause.bind(this)

    this.player = document.createElement("audio")
    this.player.pause()
    this.player.autoplay = false
    this.isPlaying = false
    this._volume = 1
    this.volume = 1

    emitter.on('mute', isMute => {
      if (isMute) {
        this.player.volume = 0
      }
      else {
        this.volume = this._volume
      }
    })
  }

  set src (src) {
    this.player.src = src

    if (this.isPlaying) {
      this.player.play()
    }
  }

  set volume (volume) {
    this._volume = volume
    this.player.volume = volume * .5
  }

  get volume () {
    return this._volume
  }

  play () {
    this.player.play()
    this.isPlaying = true
  }

  pause () {
    this.player.pause()
    this.isPlaying = false
  }
}

export const audio = new Audio()
