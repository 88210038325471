// import '@/mixins/extendedComponent'

import { ref } from 'vue'

import data from '@/assets/data'

export default {

  created () {
    window.addEventListener('resize', this._onResize)
    window.addEventListener('scroll', this._onScroll)
    window.addEventListener('keydown', this._onKeyDown)

    this.$device._onResize()
    this.$hub.emit('resize', event)
  },

  mounted () {
    this._onResize()
  },

  methods: {
    _onResize (event) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$hub.emit('resize', event)
      }, 200)
    },

    _onScroll (event) {
      this.$device._onScroll()
      this.$hub.emit('scroll', event)
    },

    _onKeyDown (event) {
      this.$hub.emit('keydown', event.keyCode)
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.$hub.emit('keydown:escape', event.keyCode)
      }
    }
  }
}
