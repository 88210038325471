<template>
  <div class="musicplayer"></div>
</template>

<script>
export default {

  data() {
    return {
      audiomusic: null
    }
  },

  computed: {
    musicIsMuted() {
      return this.$store.getters['global/musicIsMuted']
    },
    musicIsPlaying() {
      return this.$store.getters['global/musicIsPlaying']
    },
    
  },

  created () {
    this.audiomusic = document.createElement('audio')
    this.audiomusic.loop = true
    this.audiomusic.volume = this.musicIsMuted ? 0 : 0.3
    this.audiomusic.src = this.$store.state.global.sounds.background
  },

  watch: {
    musicIsMuted(newValue) {
      // Because changing volume does not work anymore on safari ios
      if(this.$device.isMobile || this.$device.isTablet){
        newValue === true ? this.audiomusic.pause() : this.audiomusic.play()
      }else{
        this.audiomusic.volume = newValue ? 0 : 0.3
      }
    },
    musicIsPlaying(newValue) {
      newValue ? this.audiomusic.play() : this.audiomusic.pause()
    },
  },
};
</script>

<style lang="stylus" scoped>
.musicplayer
  position: absolute;
  left 0
  top 0
  height 1px
  width 1px
</style>
